import { useState, useEffect, useCallback } from 'react';

export const useResponsiveImageSize = (maxImageWidthPossible?: number): { width: number; height: number } => {
  const [imageSize, setImageSize] = useState({
    width: 0,
    height: 0,
  });

  const handleResize = useCallback(() => {
    // If maxImageWitdthPossible is set, use that to avoid loading image size greater than what is possible for max column / max image size
    setImageSize({
      width:
        maxImageWidthPossible && window.innerWidth > maxImageWidthPossible ? maxImageWidthPossible : window.innerWidth,
      height: window.innerHeight,
    });
  }, [maxImageWidthPossible]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return imageSize;
};
